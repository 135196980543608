import React from 'react'

import AnimatedStroke from '../AnimatedStroke/AnimatedStroke'

import { formatTitle } from '../../helpers/stringHelpers'

export default function ConversationsActive ( props ) {

	const { conversation } = props
	const { videoTitle, videoNumber } = conversation

	return (
		<div className="conversations-active">
			<div className="wrapper">
				<div className="conversations-active__wrapper">
					<div className="conversations-active__inner-wrapper">
						<div className="conversations-active__container">
							<iframe
								className="conversations-active__player"
								src={`https://player.vimeo.com/video/${ videoNumber }`}
								title={ videoTitle }
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								frameBorder="0"
								webkitallowfullscreen="true"
								mozallowfullscreen="true"
								allowFullScreen
							/>
						</div>
					</div>
				</div>
				{ !!videoTitle &&
					<p className="conversations-active__title" dangerouslySetInnerHTML={ formatTitle( videoTitle ) } />
				}
			</div>

			<AnimatedStroke pathType={ 'conversations-path' } cssClass="conversations-active__stroke" pixelDelay={ 250 } reverse={ true } />
		</div>
	)
}