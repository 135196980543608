import React, { useState } from 'react'

import ConversationsActive from './ConversationsActive'
import ConversationsList from './ConversationsList'

export default function Conversations ( props ) {

	const { conversations } = props
	const [ active, setActive ] = useState( conversations[ 0 ] )

	return (
		<section className="conversations">
			<div className="conversations__active-container">
				<ConversationsActive conversation={ active } />
			</div>
			<ConversationsList conversations={ conversations } setActive={ setActive } />
		</section>
	)
}