import React, { useEffect, useState, useRef } from 'react'

import HomeQuotePath from '../../images/svgs/homepage-quote-path.svg'
import HomeThumbnailPath from '../../images/svgs/homepage-thumbnail-path.svg'
import ExecutiveNetworkPath from '../../images/svgs/executive-network-path.svg'
import ExecutiveVideoPath from '../../images/svgs/executive-video-path.svg'
import ContactUsPath from '../../images/svgs/contact-us-path.svg'
import WorkshopsPath from '../../images/svgs/workshops-path.svg'
import ArticlePath from '../../images/svgs/article-path.svg'
import Profile1Path from '../../images/svgs/profile-1-path.svg'
import Profile2Path from '../../images/svgs/profile-2-path.svg'
import TeamMembersPath from '../../images/svgs/team-members-path.svg'
import CommonPath from '../../images/svgs/common-path.svg'
import TrainingPath from '../../images/svgs/training-path.svg'
import CommonDivider from '../../images/svgs/divider-path.svg'
import ConversationsPath from '../../images/svgs/conversations-path.svg'

export default function AnimatedStroke ( props ) {

	const { pathType, cssClass, pixelDelay, reverse } = props

	const pathRef = useRef()

	const [ path, setPath ] = useState()
	const [ pathLength, setPathLength ] = useState( 0 )
	const [ pathComplete, setPathComplete ] = useState( false )

	useEffect(() => {
		const image = document.querySelector(`#${pathType}`)
		setPath( image )
		setPathLength( image.getTotalLength() )
	}, [ pathType ])

	useEffect(() => {

		if ( !path ) {
			return
		}

		path.style.strokeDasharray = pathLength + ' ' + pathLength
		path.style.strokeDashoffset = pathLength

		const handleScroll = () => {
			let position

			if ( pathRef.current ) position = pathRef.current.getBoundingClientRect()

			if ( position && ( position.top + pixelDelay ) < window.innerHeight && position.bottom >= 0 ) {
				// What % down is it?
				let scrollPercentage = ( position.top - window.innerHeight + pixelDelay ) / position.height
				// Length to offset the dashes
				let drawLength = scrollPercentage <= -0.99 ? pathLength * -1 : pathLength * scrollPercentage
				// Draw in reverse
				path.style.strokeDashoffset = reverse ? pathLength + drawLength : pathLength - drawLength

				// When complete, remove the dash array, otherwise shape isn't quite sharp
				if ( scrollPercentage >= 0.99 || scrollPercentage <= -0.99 ) {
					path.style.strokeDasharray = 'none'
					setPathComplete( true )
				} else {
					path.style.strokeDasharray = pathLength + ' ' + pathLength
				}
			}
		};

		if ( pathComplete ) {
			path.style.strokeDasharray = 'none'
			path.style.strokeDashoffset = 0
			return
		} else {
			window.addEventListener('scroll', handleScroll, { passive: true });
			return () => window.removeEventListener('scroll', handleScroll);
		}

	}, [ path, pathLength, pixelDelay, reverse, pathComplete ]);

	const renderPath = () => {

		const styles = {
			width: '100%',
			strokeDasharray: `${pathLength} + ' ' + ${pathLength}`,
			strokeDashoffset: `${pathLength}`
		}

		switch ( pathType ) {
			case 'homepage-quote':
				return <HomeQuotePath className="animated-stroke" style={ styles } />
			case 'homepage-thumbnail':
				return <HomeThumbnailPath className="animated-stroke" style={ styles } />
			case 'executive-network':
				return <ExecutiveNetworkPath className="animated-stroke" style={ styles } />
			case 'executive-video':
				return <ExecutiveVideoPath className="animated-stroke" style={ styles } />
			case 'contact-us':
				return <ContactUsPath className="animated-stroke" style={ styles } />
			case 'workshops':
				return <WorkshopsPath className="animated-stroke" style={ styles } />
			case 'article-path':
				return <ArticlePath className="animated-stroke" style={ styles } />
			case 'profile-1':
				return <Profile1Path className="animated-stroke" style={ styles } />
			case 'profile-2':
				return <Profile2Path className="animated-stroke" style={ styles } />
			case 'team-members-path':
				return <TeamMembersPath className="animated-stroke" style={ styles } />
			case 'common-path':
				return <CommonPath className="animated-stroke" style={ styles } />
			case 'training-path':
				return <TrainingPath className="animated-stroke" style={ styles } />
			case 'conversations-path':
				return <ConversationsPath className="animated-stroke" style={ styles } />
			case 'common-divider':
				return <CommonDivider className="animated-stroke" style={ styles } />
			default:
				return <HomeQuotePath className="animated-stroke" style={ styles } />
		}
	}

	return (
		<div className={ cssClass } ref={ pathRef }>
			{ renderPath() }
		</div>
	)
}