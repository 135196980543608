import React from 'react'

import ConversationsListItem from './ConversationsListItem'

export default function ConversationsList ( props ) {

	const { conversations, active, setActive } = props

	return (
		<div className="wrapper">
			<div className="conversations__list">
				{ conversations.map( conversation => (
					<ConversationsListItem
						conversation={ conversation }
						active={ active }
						setActive={ setActive }
						key={ conversation.id } />
				) ) }
			</div>
		</div>
	)
}