/**
 * Takes a string and replaces the '|' pipes with highlight markup
 *
 * @param {String} string String that contains '|" characters to be formated
 * @return {String} replaces piped text with highlight markup
 */
export function formatTitle( string ) {

	const regex = /\|/g;
	const match = string.match( regex );
	let cleanString = string;

	// if no pipes or more than two pipes, return initial string
	if ( !match ) { return { __html: string }; }

	// If only one pipeline, add a pipe at the end
	if ( match.length % 2 !== 0 ) {
		cleanString = `${cleanString}|`;
	}

	// Split string based on pipes
	const splitString = cleanString.split( regex );
	let newHighlightString = []

	splitString.forEach( ( string, index ) => {

		if ( index % 2 !== 0 ) {
			// Wrap text that was between pipes in the highlight markup
			newHighlightString.push( `<span class="highlight">${ string }</span>` )
		} else {
			newHighlightString.push( string )
		}
	} )

	// Concatenate highlight with start of string
	const highlightString = newHighlightString.join('')

	return { __html: highlightString }; // this object format is used to parse HTML in React
}