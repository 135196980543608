import React from 'react'
import Img from 'gatsby-image'
import moment from 'moment'

import { BsArrowRight } from 'react-icons/bs'

export default function ConversationItem ( props ) {

	const { conversation, setActive } = props

	const handleClick = ( conversation ) => {

		setActive( conversation )

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}

	return (
		<div className="conversations__list-item">
			<div className="conversations__list-item-wrapper">
				
				<div className="conversations__content-container">
					<h3 className="conversations__item-title">{ conversation.title }</h3>
					<span className="conversations__item-date">{ moment( conversation.dateField ).format( 'MMMM YYYY' ) }</span>
					<div className="conversations__item-summary">{ conversation.summary }</div>

					<button className="conversations__item-toggle" onClick={ () => handleClick( conversation ) }>
						View conversation
						<BsArrowRight className="conversations__item-toggle-icon" />
					</button>
				</div>
				<div className="conversations__image-container">
					<Img fluid={{ ...conversation.thumbnail.imageFile.childImageSharp.fluid, aspectRatio: 16/9 }} className="conversations__image" />
				</div>
			</div>
		</div>
	)
}