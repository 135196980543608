import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import Conversations from '../components/Conversations/Conversations'

export default function ConversationsPage({ data: { strapi: { conversations } }, location }) {

	return (
		<>
			<SEO title="Conversations" pathname={ location.pathname } />

			<div className="content-page content-page--has-header">
				<Conversations conversations={ conversations } />
			</div>
		</>
	)
}

export const query = graphql`
	query conversationsPageQuery {
		strapi {
			conversations(sort: "dateField:DESC") {
				id
				title
				dateField
				summary
				videoTitle
				videoNumber
				thumbnail {
					url
					imageFile {
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			}
		}
	}
`